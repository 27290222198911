.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}


.itp-select2 {
  // font-size: 0.875rem;
  border-radius: 0.25rem;
  background-color: #fff;

  &.is-invalid {
    .vs__dropdown-toggle {
      border-color: #dc3545;
    }
  }

  &.is-valid {
    .vs__dropdown-toggle {
      border-color: #28a745;
    }
  }

  .vs__selected-options {
    // padding: 0 7px;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: hidden;

    & > input::placeholder {
      color: gray;
    }
  }

  .vs__dropdown-toggle {
    padding-bottom: 0;
    overflow: hidden;
  }

  .vs__selected,
  .vs__search,
  .vs__actions {
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 3px;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .vs__selected {
    padding: 0 5px;
    margin: 2px;
    flex-wrap: nowrap;
    text-wrap: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.size-sm {
    .vs__dropdown-toggle,
    &.vs--open.vs__dropdown-toggle {
      min-height: calc(1.5em + 0.5rem + 2px);
      height: auto;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.5rem;
      font-size: 0.875rem;
    }

    .vs__selected,
    .vs__search,
    .vs__actions {
      padding: 0;
      margin: 0;
    }

    .vs__actions {
      padding-right: 0.5rem;
    }
  }
}
